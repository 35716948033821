const initialState = {
  lang: "en",
};

const CHANGE_LANG = "CHANGE_LANG";

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return { ...state, lang: action.lang };
    default:
      return state;
  };
};

export const changeLang = lang => ({
  type: CHANGE_LANG,
  lang,
});